<template>
  <v-container fluid>
    <v-row>
      <v-breadcrumbs :items="breadCrumbsItems" large>
        <template v-slot:divider>
          <v-icon size="11">mdi-greater-than</v-icon>
        </template>
      </v-breadcrumbs>
      
    </v-row>

    <v-layout wrap>
      <v-col cols="12" md="3">
        <UserMenu></UserMenu>
      </v-col>

      <v-col cols="12" md="9">
        <v-row>
          <v-col cols="12">
            <h3>
              <v-icon style="font-size:35px;" class="mr-4"
                >mdi-cog-outline</v-icon
              >
              Settings
            </h3>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="rowBg mt-5">
            <h5 class="mt-5">Personal information</h5>

            <!---- Upload Member Avatar---->
            <v-dialog v-model="dialogUploadPicture" width="700">
              <template v-slot:activator="{ on, attrs }">
                <v-btn text color="blue" v-bind="attrs" v-on="on">
                  Upload picture
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="text-h6 grey lighten-2">
                  <v-icon class="mr-5">mdi-account-box</v-icon> Upload My Photo
                  <v-spacer></v-spacer>
                  <v-btn
                    icon
                    color="black"
                    @click="dialogUploadPicture = false"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text class="mt-4">
                  <v-layout wrap class="photoBorderStyle">
                    <v-icon class="mr-5" color="#BD2121"
                      >mdi-alert-circle-outline</v-icon
                    >
                    Your photo will be displayed shortly after upload
                  </v-layout>

                  <v-row class="mt-4">
                    <v-col
                      cols="10"
                      md="4"
                      offset="1"
                      style="border:2px dotted #c2c2c2; padding:10px;"
                    >
                      <v-row>
                        <v-col cols="5" offset="3">
                          <v-btn dark fab @click="onPickAvatar" class="#1A6197">
                            <v-icon dark align="center">mdi-camera</v-icon>
                          </v-btn>
                          <input
                            type="file"
                            style="display:none;"
                            ref="fileInput"
                            accept="image/*"
                            @change="avatarPicked"
                          />
                          <v-progress-linear
                            class="mt-3"
                            color="#7CBF46"
                            v-show="imageLoader"
                            :indeterminate="true"
                          ></v-progress-linear>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" v-show="preview != true">
                          <v-img
                            class="centerImg"
                            contain
                            :src="'img/imageUploader.png'"
                            width="250"
                            align="center"
                          >
                          </v-img>
                          <v-btn class="buttonStyle2"> Submit Photo</v-btn>
                        </v-col>

                        <v-col cols="12" v-show="preview">
                          <v-img
                            class="centerImg"
                            contain
                            :src="profileImage"
                            width="250"
                            align="center"
                          >
                          </v-img>
                          <v-btn class="buttonStyle2" @click="uploadPhoto"> Submit Photo</v-btn>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col
                      cols="10"
                      md="5"
                      offset="1"
                      style="border:2px dotted #c2c2c2;"
                    >
                      <h6 align="center">Uploading Rules</h6>
                      <v-img
                        class="centerImg"
                        contain
                        :src="'img/imgSample.png'"
                        width="150"
                        align="center"
                      >
                      </v-img>
                      <div>
                        <ul>
                          <li>
                            Please upload a photo that matches the gender, age
                            and status details in your personal profile
                          </li>
                          <li>
                            Use a photo that is appropraite for a business
                            setting. Group photos are not allowed
                          </li>
                          <li>
                            Photos violating our rules will be removed without
                            notice.
                          </li>
                        </ul>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-dialog>

            <!---- Edit Member Profile---->
            <v-dialog v-model="dialogEditProfile" width="700">
              <template v-slot:activator="{ on, attrs }">
                <v-btn text color="blue" v-bind="attrs" v-on="on">
                  Edit Profile
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="text-h6 grey lighten-2">
                  <v-icon class="mr-5">mdi-account-box</v-icon> Edit Member
                  Profile
                  <v-spacer></v-spacer>
                  <v-btn icon color="black" @click="dialogEditProfile = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text class="mt-4">
                  <v-layout wrap>
                    <v-btn text>
                      <v-icon class="mr-5" color="#BD2121"
                        >mdi-alert-circle-outline</v-icon
                      >
                      Deactivate Account
                    </v-btn>
                  </v-layout>

                  <v-row class="mt-3">
                    <v-col
                      cols="10"
                      offset="1"
                      style="border:2px dotted #c2c2c2;"
                    >
                      <v-row>
                        <v-col cols="6">
                          <v-text-field
                            dense
                            v-model="userDetail.name"
                            type="text"
                            label="Full name"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6">
                          <v-text-field
                            dense
                            v-model="userDetail.email"
                            type="email"
                            label="Email"
                            disabled
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="6" align="center" v-if="userDetail.isConfirmed == true">
                          <p style="color:#1D8B61;">
                            Email Confirmed
                            <v-icon color="#1D8B61"
                              >mdi-alert-circle-check-outline</v-icon
                            >
                          </p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6">
                          <v-text-field
                            dense
                            v-model="userDetail.address"
                            type="text"
                            label="Contact Address"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6">
                          <v-text-field
                            dense
                            v-model="userDetail.zipCode"
                            type="text"
                            label="Zip/Postal Code"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6">
                          <v-text-field
                            dense
                            v-model="userDetail.phoneNumber"
                            type="text"
                            label="Phone Number"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-btn class="buttonStyle2">
                    Update
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="rowBg mt-5">
            <h5 class="mt-5">Security information</h5>

            <template>
                <v-btn text color="blue" @click="sendEmailUpdateCode">
                  Change Email Address
                </v-btn>
            </template>

            <!---- Change Email Address---->
            <v-dialog v-model="dialogChangeEmail" width="700" persistent>
              
              <v-card>
                <v-card-title class="text-h6 grey lighten-2">
                  <v-icon class="mr-5">mdi-account-box</v-icon> Confirm Your
                  Email Address
                  <v-spacer></v-spacer>
                  <v-btn icon color="black" @click="dialogChangeEmail = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text class="mt-4">
                  <v-row class="mt-3">
                    <v-col cols="12" style="border:2px dotted #c2c2c2;">
                        <div class="my-10 mx-5">
                            <p>We've sent an email to: <b>{{ userDetail.email }}</b></p>
                            <p>Please check your email address for a confirmation code to verify your email ownership.</p>
                        </div>

                        <div>
                            <v-btn outlined color="black" :to="'/account/email_confirmation/' + userDetail.userId + '/' + userDetail.email">
                                Click to confirm email
                            </v-btn>
                        </div>
                        <div class="my-10 mx-5">
                            <p class="mt-5">
                                Didn't recieve our confirmation email?
                            </p>
                            <p>
                                <ol>
                                    <li>
                                        Check your spam box.
                                    </li>
                                    <li>
                                        Resend confirmation email to {{ userDetail.email }} ? <v-btn text color="blue" @click.prevent="resendEmail(userDetail.email)"> Resend now</v-btn>
                                        <div class="resendEmailStyle" v-if="emailResentStatus">
                                            We've sent a confirmation email to your inbox. To resend confirmation, please wait <span style="color:#FF1313;">53</span> seconds
                                        </div>
                                    </li>
                                </ol>
                           </p>
                        </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-dialog>

             <template >
                <v-btn text color="blue" :to="'/account/password_update/' + userDetail.email">
                  Change password
                </v-btn>
              </template>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="rowBg mt-5">
            <h5 class="mt-5">Activate email notifications</h5>
         
            <!---- Activate Email Notifications---->
            <v-dialog v-model="dialogEmailNotification" width="600">
              <template v-slot:activator="{ on, attrs }">
                <v-btn text color="blue" v-bind="attrs" v-on="on">
                  Activate Now
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="text-h6 grey lighten-2">
                  Receive Email Notification
                  <v-spacer></v-spacer>
                  <v-btn icon color="black" @click="dialogEmailNotification = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text class="mt-4">
                  <v-row wrap>
                      <v-col cols="2">
                          <v-icon size="40" class="mr-5" color="#2776ED"
                      >mdi-alert-circle-outline</v-icon
                    >
                      </v-col>
                     <v-col cols="10">
                        Enable to receive emails from B2B pharma and their sellers with deals, item recommendations and services 
                     </v-col>
                  </v-row>
                  <v-row>
                      <v-col cols="12">
                          <v-btn class="buttonStyle">
                              Enable
                          </v-btn>

                           <v-btn text>
                              Disable
                          </v-btn>
                      </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>

        <!---- 2FA Settings---->
        <v-row>
          <v-col cols="12" class="rowBg mt-5">
            <h5 class="mt-5">2factor Authentication</h5>
             <template >
                
                
                <v-dialog v-model="dialogConfirmPassword" width="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" v-if="!userDetail.isTwoFA" text color="blue">
                      Activate
                    </v-btn>
                    <v-btn v-bind="attrs" v-on="on" v-else  text color="blue">
                      Deactivate
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="text-h6 grey lighten-2">
                      <v-icon class="mr-5">mdi-account-box</v-icon> Confirm Password
                      <v-spacer></v-spacer>
                      <v-btn icon color="black" @click="dialogConfirmPassword = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-text class="mt-5">
                      <v-row>
                        <v-text-field
                          name="password"
                          label="Password"
                          outlined
                          :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                          :rules="[rules.required, rules.min]"
                          :type="show3 ? 'text' : 'password'"
                          id="password"
                          dense
                          v-model="password"
                          @click:append="show3 = !show3"
                          color="#7CBF46"
                        ></v-text-field>
                      </v-row>
                      <v-row>
                        <p class="text-danger" align="center">
                        {{ errorMessage }}
                        </p>
                      </v-row>
                      <v-row>
                      <v-btn
                          class="buttonStyle"
                          @click.native="ConfirmPassword"
                          block
                        >
                          Submit
                        </v-btn>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-dialog>
                <!-- <v-btn v-else text color="blue" @click="deactivate2fa" >
                  Deactivate
                </v-btn> -->
              </template>
          </v-col>
        </v-row>

      </v-col>
    </v-layout>
  </v-container>
</template>

<script>
import UserMenu from "../others/UserMenu.vue";

export default {
  name: "UserAccountSetting",

  components: {
    UserMenu,
  },

  data: () => ({
    dialogUploadPicture: false,
    dialogEditProfile: false,
    dialogConfirmPassword:false,
    dialogChangeEmail: false,
    dialogEmailNotification: false,
    emailResentStatus:false,
    preview: false,
    errorMessage: "",
    imageLoader: false,
    breadCrumbsItems: [
      {
        text: "Home",
        disabled: false,
        href: "/#/overview/user/dashboard/en",
      },
      {
        text: "Account Setting",
        disabled: true,
        href: "/#/user/account-settings",
      },
    ],
    profileImage: "",
    userDetail: {},
    userId:"",
    selectedFile: null,
    show3: false,
    password: "",
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
    },
  }),
  mounted() {
    this.setAuthenticatedUser();
  },

  methods: {
    setAuthenticatedUser() {
      this.userId = localStorage.getItem("userId");
      this.$http
        .get(`${process.env.VUE_APP_URL}user/` + this.userId)
        .then((response) => {
          this.userDetail = response.data.data;
        })
    },
    
    onPickAvatar() {
      this.imageLoader = true;
      this.$refs.fileInput.click();
    },

    avatarPicked(e) {
      this.selectedFile=e.target.files[0];

      var fileReader = new FileReader();

      fileReader.readAsDataURL(e.target.files[0]);

      fileReader.onload = (e) => {
        this.preview = true;
        this.imageLoader = false;
        this.profileImage = e.target.result;
      };
    },

    uploadPhoto()
    {
      this.imageLoader = true;
      const formData = new FormData();
      formData.append('avatar', this.selectedFile);

      this.$http
        .put(`${process.env.VUE_APP_URL}user/picture/` + this.userId,
        formData,
        )
        .then(() => {
          this.imageLoader = false;
          location.reload();
        })
        .catch((err) => {
          this.imageLoader = false;
          console.log(err.response)
        });
    },

    sendEmailUpdateCode(){
      const data = {
          "userId":localStorage.getItem("userId"),
          "email":this.userDetail.email,
      }

      this.$http
        .post(`${process.env.VUE_APP_URL}new-email-verification/send`,data,{
          headers: {
            'apiKey':`${process.env.VUE_APP_KEY}`
          }
        })
        .then((response) => {
          if (response.data.success == true) {
            this.dialogChangeEmail = true
          }
        })
        .catch((error) => {
          this.loader = false;
          this.errorMessage = error.response.data.data;
        });
    },

    resendEmail(email) {
        console.log(email);
        this.emailResentStatus = true
    },

    deactivate2fa() {
      this.$http
        .put(`${process.env.VUE_APP_URL}user/twofa/` + this.userId,
        )
        .then(() => {
          location.reload();
        })
        .catch((err) => {
          console.log(err.response)
        });
    },

    ConfirmPassword()
    {
      this.errorMessage = "";

      const data = {
        password:this.password
      }

      this.$http
        .put(`${process.env.VUE_APP_URL}user/twofa/` + this.userId, data
        )
        .then((response) => {
            console.log(response);
            
            if(response.data.data.isTwoFA == false)
            {
              location.reload();
            }
            else
            {
              localStorage.setItem("otherfile", this.password);
              this.$router.push({
                name: "pharma2FABackupcodes",
                params: { user_id: this.userId }
              });
            }
        })
        .catch((err) => {
          console.log(err.response)
          this.errorMessage = err.response.data.errors[0];
        });
    }
  },
};
</script>

<style scoped>
.rowBg {
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 0px 0px 20px 20px;
}

.iconStyle {
  font-size: 40px;
  color: #dd6464;
  transform: rotate(180deg);
}

.buttonStyle {
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 10px;
  color: #ffffff !important;
  font-size: 16px;
  padding-left: 45px !important;
  padding-right: 45px !important;
  padding-top: 30px;
  padding-bottom: 30px;
}

.buttonStyle2 {
  background: linear-gradient(180.88deg, #72bd61 33.53%, #111212 95.69%);
  border-radius: 10px;
  color: #ffffff !important;
  font-size: 14px;
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-left: 25px;
}

li {
  font-size: 12px;
  margin-top: 5px;
}

.defaultAddressStyle {
  width: 130px;
  height: auto;
  background: rgba(17, 18, 18, 0.47);
  color: #ffffff;
  padding: 5px;
  border-top-left-radius: 5px;
}

.photoBorderStyle {
  border: 1px solid #4176c7;
  padding: 5px;
}

.centerImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.resendEmailStyle {
    background-color: #FAFAFA;
    padding: 10px;
    color:black;
}
</style>
